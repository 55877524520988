import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import Document from '../components/Document';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SidebarLogin from '../components/SidebarLogins';
import Wrapper from '../components/Wrapper';

const TaxationPage = ({ data }) => (
  <Layout>
    <Wrapper>
      <SEO title="Taxation" />
      <article className="max-w-2xl mx-auto w-full">
        <div className="max-w-md pt-4 px-4">
          <Link to="/taxation/">
            <h1 className="text-5xl">Taxation Services</h1>
          </Link>
          <hr />
        </div>
        <div className="flex flex-wrap">
          <div className="max-w-md pb-4 px-4 w-full">
            <h2>Taxation</h2>
            <p>
              We proactively monitor your profits and plan for your tax, it’s
              unfortunately unavoidable however it is something that can be
              minimised and used effectively can even provide long term benefits
              in future income years.
            </p>

            <h2>GST</h2>
            <p>
              Every day GST effects your business, make sure your recording
              everything correctly, and taking advantage of the tricks and
              avoiding the traps to make sure it is simple and painless.
            </p>

            <h2>Capital Gains Tax</h2>
            <p>
              If you have bought or sold an asset, if your home is over 5 acres,
              there is capital gains tax involved in the sale. How much you pay
              is determined by your income, there is no separate CGT tax rate.
              It is your marginal tax rate. But there are a number of discounts
              and rollovers that can be available to make sure you can reduce
              how much you need to pay.
            </p>
          </div>
          <div className="flex-1 pb-4 px-4 w-full lg:w-1/4">
            <div className="lg:ml-24">
              <h2>Logins</h2>
              <SidebarLogin />
              <h2>Helpful Documents</h2>
              <ul className="list-reset leading-loose">
                <Document
                  link="/taxation/capital-gains-tax.pdf"
                  title="Capital Gains Tax"
                />
              </ul>
              <Img fluid={data.imageTax.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </article>
    </Wrapper>
  </Layout>
);

TaxationPage.propTypes = {
  data: PropTypes.object,
};

export default TaxationPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageTax: file(relativePath: { eq: "tax.jpg" }) {
      ...fluidImage
    }
  }
`;
